import React from 'react'
import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import { getToken } from '../functions/token'
import { Container } from 'react-bootstrap'

type IProps = {
	children: JSX.Element
}

export function ProtectedRoute({ children }: IProps) {
	if (!getToken()) {
		window.location.href = '/#/login'
		return <></>
	} else {
		return (
			<div id="page-container">
				<div id="content-wrap">
					<Navigation />
					<Container>{children}</Container>
					<Footer />
				</div>
			</div>
		)
	}
}
