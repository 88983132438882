import ProductCollection from '../models/ProductCollection'
import CategoryCollection from '../models/CategoryCollection'
import GroupCollection from '../models/GroupCollection'
import Group from '../models/Group'
import Contact from '../models/Contact'
import ObserverType from './ObserverType'
import BASISCATEGORIES from '../constants/basisCategories'
import { Language } from '../types/Language'

type SearchType = 'all' | 'number' | 'year'

interface data {
	categoryCollection: CategoryCollection
	groupCollection: GroupCollection
	productCollection: ProductCollection
	group: Group
	contact: Contact
	showSidebar: boolean
	view: number
	language: Language
	url: {
		anzahl: number
		seite: number
		katId: number
		level: number
		sortAttribute: string
		sortOrder: string
		searchTerm: string
		searchType: SearchType
		categories: number[]
	}
}

class Store {
	observers: ObserverType[]

	data: data

	constructor() {
		this.observers = []
		this.data = {
			categoryCollection: new CategoryCollection(),
			groupCollection: new GroupCollection(),
			productCollection: new ProductCollection(),
			group: new Group(),
			contact: new Contact(),
			showSidebar: true,
			view: 1,
			language: 'de',
			url: {
				anzahl: 9,
				seite: 1,
				katId: 0,
				level: 1,
				sortAttribute: 'all',
				sortOrder: 'ASC',
				searchTerm: '',
				searchType: 'all',
				categories: BASISCATEGORIES,
			},
		}
	}

	attach(observer: ObserverType): void {
		this.observers.push(observer)
	}

	detach(observer: ObserverType): void {
		this.observers = this.observers.filter((o) => o !== observer)
	}

	print(): void {
		console.info(this.data)
	}

	notify(): void {
		this.observers.forEach((observer) => observer.update())
	}
}

export default new Store()
