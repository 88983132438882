import VarietyCollection from '../models/VarietyCollection'
import ColorCollection from '../models/ColorCollection'
import ParentProduct from '../models/ParentProduct'

export default class Product extends ParentProduct {
	varieties: VarietyCollection = new VarietyCollection()
	colors: ColorCollection = new ColorCollection()

	hasVarieties(): boolean {
		return !(this.varieties.collection.length === 0)
	}

	hasColors(): boolean {
		return !(this.colors.collection.length === 0)
	}
}
