import Observer from '../observerstore/Observer'
import React from 'react'
import Group from '../models/Group'
import Letter from '../models/Letter'
import Product from '../models/Product'
import Row from 'react-bootstrap/Row'
import ImageGallery from 'react-image-gallery'
import Col from 'react-bootstrap/Col'
import '../css/letter.css'

interface ImageGalleryItem {
	original: string
	thumbnail: string
	originalAlt: string
	thumbnailAlt: string
	originalTitle: string
	thumbnailTitle: string
}

interface Props {
	input: Group | Product
}
interface State {
	detail: boolean
}

export default class Letters extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			detail: false,
		}
	}

	getLettersFromGroup(input: Group): Letter[] {
		let products: Product[] = input.collection

		let all: Letter[] = []
		let single: Letter[] = []
		let multi: Letter[] = []

		// Alle Produkte suchen
		for (let p of products) {
			for (let l of p.letters.all()) {
				all.push(l)
			}
		}

		// Zwischen Multi und Single-Stamp unterscheiden
		for (let i of all) {
			let temp = all.filter((l) => i.id === l.id)
			if (temp.length === 1) {
				single.push(i)
			} else {
				let tempBool: Boolean = true
				for (let z of multi) {
					if (i.id === z.id) {
						tempBool = false
					}
				}
				if (tempBool) multi.push(i)
			}
		}

		return single.concat(multi)
	}

	getLettersFromProduct(input: Product): Letter[] {
		return input.letters.collection
	}

	getLetterPicture(letter: Letter): ImageGalleryItem[] {
		const images: ImageGalleryItem[] = []
		images.push({
			original: letter.getPicturePath().large,
			thumbnail: letter.getPicturePath().thumbnail,
			originalAlt: `Briefmarke: ${letter.name}`,
			thumbnailAlt: `Briefmarke: ${letter.name}`,
			originalTitle: letter.name,
			thumbnailTitle: letter.name,
		})
		return images
	}

	getLetters(): Letter[] {
		if (this.props.input instanceof Group) {
			return this.getLettersFromGroup(this.props.input)
		} else {
			return this.getLettersFromProduct(this.props.input)
		}
	}

	render() {
		let letters: Letter[] = this.getLetters()
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				{letters.map((l, i) => (
					<div className="letterbox letterbox-font" key={i}>
						<Row>
							<Col xs={12} md={6}>
								<ImageGallery
									items={this.getLetterPicture(l)}
									showPlayButton={false}
									showThumbnails={false}
								/>
							</Col>
							<Col xs={6} md={6}>
								<div
									className="content"
									dangerouslySetInnerHTML={{
										__html: l.beschrieb,
									}}
								></div>
							</Col>
						</Row>
					</div>
				))}
			</React.Fragment>
		)
	}
}
