import React, { Component } from 'react'
import { ProtectedRoute } from '../componentsFunctional/ProtectedRoute'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class AGB extends Component {
	render() {
		return (
			<ProtectedRoute>
				<Container>
					<code className="component_info">AGB.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>AGB</h1>
								<p>
									Danke, dass Sie unseren digitalen
									Briefmarkenkatalog nutzen.
									<br />
									Bitte beachten Sie, dass alle Angaben ohne
									Gewähr sind.
								</p>

								<p>
									Anregungen und Korrekturen bitte an:
									<br />
									<a href="mailto:rolf.hauser@rj-stamp.ch">
										rolf.hauser@rj-stamp.ch
									</a>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</ProtectedRoute>
		)
	}
}

export default AGB
