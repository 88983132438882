export default interface LanguageArray {
	de: string
	fr: string
}

export const months: LanguageArray[] = [
	{ de: 'Januar', fr: 'Janvier' },
	{ de: 'Februar', fr: 'Février' },
	{ de: 'März', fr: 'Mars' },
	{ de: 'April', fr: 'Avril' },
	{ de: 'Mai', fr: 'Mai' },
	{ de: 'Juni', fr: 'Juin' },
	{ de: 'Juli', fr: 'Juillet' },
	{ de: 'August', fr: 'Août' },
	{ de: 'September', fr: 'Septembre' },
	{ de: 'Oktober', fr: 'Octobre' },
	{ de: 'November', fr: 'Novembre' },
	{ de: 'Dezember', fr: 'Décembre' },
]
