import Probation from '../models/Probation'

export default class ProbationList {
	all: Probation[] = []

	constructor() {
		this.all = []
	}

	translateApi(json: any) {
		if (json !== undefined) {
			this.all = json.map((p: any) => {
				let probation: Probation = new Probation()
				probation.translateApi(p)
				return probation
			})
		}
	}

	getAll(): Probation[] {
		return this.all
	}
}
