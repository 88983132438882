import Category from './Category'
import { REQUESTURL } from '../constants/urls'
import { getHeaders } from '../functions/fetchHelpers'

export default class CategoryCollection {
	collection: Category[]
	parentCategory: Category
	allCategories: Category

	constructor() {
		this.collection = []
		this.parentCategory = new Category()
		this.allCategories = new Category()
	}

	getIdArray(): number[] {
		return this.collection.map((c) => {
			return c.id
		})
	}

	findTitle(categoryId: number): string {
		let found = this.collection.find(
			(category) => category.id === categoryId
		)
		if (found !== undefined) {
			return found.name
		} else {
			return 'Schweiz'
		}
	}

	async readApi() {
		const url = `${REQUESTURL}category-list/all`
		try {
			const response: any = await fetch(url, {
				method: 'GET',
				headers: getHeaders(),
			})
			const json = await response.json()

			this.translateApi(json.categoryList)
		} catch (e) {
			console.error('error: ' + e)
		}
	}

	translateApi(jsonArray: any[]): void {
		let mainCategory: Category = new Category()
		mainCategory.translateApi(jsonArray[0])
		this.getChildsFromCategory(mainCategory, jsonArray)
		this.allCategories = mainCategory
	}

	getChildsFromCategory(category: Category, jsonArray: any[]): void {
		const childs: Category[] = jsonArray.filter((e: any) => {
			return e.idParent === category.id
		})

		for (let i of childs) {
			let childCategory: Category = new Category()
			childCategory.translateApi(i)
			this.getChildsFromCategory(childCategory, jsonArray)
			category.childs.push(childCategory)
		}
	}

	getCategoryChildNumbers(categoryId: number): number[] {
		let output: number[] = [categoryId]
		const category: Category | undefined = this.getCategoryChild(categoryId)
		if (category) {
			output = this.getCategoryChildNumbersRecursive(category, output)
		}
		//output = [...new Set(output)];
		output = output.sort(function(a: number, b: number) {
			return a - b
		})
		output = output.filter(function(item: number, pos: number) {
			return output.indexOf(item) === pos
		})
		return output
	}

	getCategoryChildNumbersRecursive(
		category: Category,
		output: number[]
	): number[] {
		if (category.childs.length === 0) {
			output.push(category.id)
		} else {
			for (let child of category.childs) {
				output = this.getCategoryChildNumbersRecursive(child, output)
			}
		}
		return output
	}

	getCategoryChild(categoryId: number): Category | undefined {
		let category: Category | undefined = undefined
		for (let i = 0; i < this.allCategories.childs.length; i++) {
			if (!category) {
				category = this.recursiveSearchCategory(
					categoryId,
					this.allCategories.childs[i]
				)
			}
		}
		return category
	}

	recursiveSearchCategory(
		categoryId: number,
		category: Category
	): Category | undefined {
		if (category.id === categoryId) {
			return category
		} else {
			let output: Category | undefined
			for (let i = 0; i < category.childs.length; i++) {
				if (!output) {
					output = this.recursiveSearchCategory(
						categoryId,
						category.childs[i]
					)
				}
			}
			return output
		}
	}

	searchCategoryInChilds(
		category: Category,
		name: string
	): Category | undefined {
		return category.childs.find((e: Category) => {
			return e.name === name
		})
	}

	//addCategoryChild(category: Category, json: JSON, level: number): void {
	//const categoryChild: Category = new Category();
	//categoryChild.translateApi(json, level);
	//category.childs.push(categoryChild);
	//}

	getInvolvedIds(): number[] {
		// TODO: implement
		return []
	}
}
