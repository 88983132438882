import React from 'react'
import Col from 'react-bootstrap/Col'
import AdditionalInformations from '../components/AdditionalInformations'
import Row from 'react-bootstrap/Row'
import Picture from '../interfaces/Picture'
import Variety from '../models/Variety'
import PricesVarieties from '../components/PricesVarieties'
import { Language } from '../types/Language'
import Observer from '../observerstore/Observer'
import ImageGallery from 'react-image-gallery'
import Store from '../observerstore/Store'
const $ = Store.data

interface ImageGalleryItem {
	original: string
	thumbnail: string
	originalAlt: string
	thumbnailAlt: string
	originalTitle: string
	thumbnailTitle: string
}

interface Props {
	variety: Variety
}

interface State {
	bilder: Picture[]
	language: Language
}

export default class VarietyDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			bilder: this.props.variety.abart_pictures,
			language: $.language,
		}
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	imagesVerarbeiten(variety: Variety): ImageGalleryItem[] {
		const images: ImageGalleryItem[] = []
		for (let v of variety.abart_pictures) {
			images.push({
				original: v.large,
				thumbnail: v.thumbnail,
				originalAlt: `Briefmarke: ${variety.name_de}`,
				thumbnailAlt: `Briefmarke: ${variety.name_de}`,
				originalTitle: variety.name_de,
				thumbnailTitle: variety.name_de,
			})
		}
		return images
	}

	renderSpecialInfos(): React.ReactNode {
		let ausgabetag: string = this.props.variety.ausgabetag
		let druckauflage: string = this.props.variety.druckauflage

		if (ausgabetag !== '' && druckauflage !== null) {
			return (
				<div>
					{'Ausgabedatum: ' + ausgabetag}
					<br />
					{'Druckauflage ca. ' + druckauflage}
					<hr className="zero-line" />
				</div>
			)
		} else if (ausgabetag !== '' && druckauflage === null) {
			return (
				<div>
					{'Ausgabedatum: ' + ausgabetag}
					<hr className="zero-line" />
				</div>
			)
		} else if (ausgabetag === '' && druckauflage !== null) {
			return (
				<div>
					{'Druckauflage ca. ' + druckauflage}
					<hr className="zero-line" />
				</div>
			)
		}
	}

	renderPrices(): React.ReactNode {
		return <PricesVarieties product={this.props.variety} />
	}

	render(): React.ReactNode {
		return (
			<>
				<article
					style={{
						border: '1px solid #ac9259',
						padding: '10px',
					}}
				>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col sm={6} className="detail">
							{this.props.variety !== undefined && (
								<ImageGallery
									additionalClass="gallery-with-thumbnails"
									items={this.imagesVerarbeiten(
										this.props.variety
									)}
									showPlayButton={false}
									showThumbnails={
										this.props.variety.abart_pictures
											.length > 1
									}
									slideDuration={0}
								/>
							)}
						</Col>
						<Col sm={6} className="detail">
							<div className="set-card-title">
								<h1 style={{ fontSize: '1rem' }}>
									<span className="mute">
										CHE
										{' ' + this.props.variety.hauser_zusatz}
									</span>
									&nbsp;&nbsp;
									{this.props.variety.znr}
									{' ' + this.props.variety.abart}
								</h1>
								{this.props.variety.abart_beschrieb && (
									<p>
										<b>
											{' ' +
												this.props.variety
													.abart_beschrieb}
										</b>
									</p>
								)}
							</div>
							<hr className="zero-line" />
							<p>
								<span className="year">
									{this.props.variety.jahr}
								</span>
								&nbsp;
								{this.props.variety.name_de}
								{this.props.variety.langbeschrieb_de !== '' && (
									<>
										<br />
										{this.props.variety.langbeschrieb_de}
									</>
								)}
								<br />
								{this.props.variety.getKurzbeschrieb(
									this.state.language
								)}
							</p>
							<hr className="zero-line" />
							{this.renderSpecialInfos()}
							{this.renderPrices()}
							<AdditionalInformations
								product={this.props.variety}
							/>
						</Col>
					</Row>
				</article>
			</>
		)
	}
}
