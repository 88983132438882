import Observer from '../observerstore/Observer'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
//import ToggleButton from "react-bootstrap/ToggleButton";
import React from 'react'
import Store from '../observerstore/Store'
const $ = Store.data

interface Props {}
interface State {
	showSidebar: boolean
}

export default class SidebarButton extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			showSidebar: false,
		}
		this.click = this.click.bind(this)
	}

	update() {
		this.setStateNStore({
			showSidebar: $.showSidebar,
		})
	}

	click(_e: any) {
		$.showSidebar = !this.state.showSidebar
		Store.notify()
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<ButtonGroup toggle className="mb-2">
					<div className="gold" onClick={this.click}>
						<svg
							fill="currentColor"
							style={{ marginTop: '6px' }}
							width="20"
							height="20"
							x="0px"
							y="0px"
							viewBox="0 0 13.3 15"
						>
							{this.state.showSidebar && (
								<path d="M8.9,1.3L7.5,0L0,7.5L7.5,15l1.3-1.3L2.7,7.5L8.9,1.3z" />
							)}
							{this.state.showSidebar && (
								<path d="M13.3,1.3L11.9,0L4.4,7.5l7.5,7.5l1.3-1.3L7.1,7.5L13.3,1.3z" />
							)}

							{!this.state.showSidebar && (
								<path d="M4.4,1.3L5.8,0l7.5,7.5L5.8,15l-1.3-1.3l6.2-6.2L4.4,1.3z" />
							)}
							{!this.state.showSidebar && (
								<path d="M0,1.3L1.3,0l7.5,7.5L1.3,15L0,13.7l6.2-6.2L0,1.3z" />
							)}
						</svg>
					</div>
				</ButtonGroup>
			</React.Fragment>
		)
	}
}
