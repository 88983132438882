import { Language } from '../types/Language'

export function details(language: Language) {
	switch (language) {
		case 'de':
			return 'Details'
		case 'fr':
			return 'Détails'
		default:
			return 'Details'
	}
}

export function colors(language: Language) {
	switch (language) {
		case 'de':
			return 'Farben'
		case 'fr':
			return 'Couleurs'
		default:
			return 'Farben'
	}
}

export function variety(language: Language) {
	switch (language) {
		case 'de':
			return 'Abarten'
		case 'fr':
			return 'Variétés'
		default:
			return 'Abarten'
	}
}

export function seals(language: Language) {
	switch (language) {
		case 'de':
			return 'Stempel'
		case 'fr':
			return 'Tampon'
		default:
			return 'Stempel'
	}
}

export function probations(language: Language) {
	switch (language) {
		case 'de':
			return 'Proben'
		case 'fr':
			return 'Répéter'
		default:
			return 'Proben'
	}
}

export function fakes(language: Language) {
	switch (language) {
		case 'de':
			return 'Fälschungen'
		case 'fr':
			return 'Falsification'
		default:
			return 'Fälschungen'
	}
}

export function unlimited(language: Language) {
	switch (language) {
		case 'de':
			return 'unbeschränkt'
		case 'fr':
			return 'illimité'
		default:
			return 'unbeschränkt'
	}
}

export function letter(language: Language) {
	switch (language) {
		case 'de':
			return 'Briefe'
		case 'fr':
			return 'Lettres'
		default:
			return 'Briefe'
	}
}

export function fdc(language: Language) {
	switch (language) {
		case 'de':
			return 'FDC'
		case 'fr':
			return 'FDC'
		default:
			return 'FDC'
	}
}
