import { Language } from '../types/Language'

export function nr(language: Language) {
	switch (language) {
		case 'de':
			return 'Nr.'
		case 'fr':
			return 'Non.'
		default:
			return 'Nr.'
	}
}

export function jahr(language: Language) {
	switch (language) {
		case 'de':
			return 'Jahr'
		case 'fr':
			return 'Année'
		default:
			return 'Jahr'
	}
}
