import React from 'react'
import Store from '../observerstore/Store'

abstract class Main<Props, State> extends React.Component<Props, State> {
	componentDidMount() {
		Store.attach(this)
	}

	componentWillUnmount() {
		Store.detach(this)
	}

	update() {}

	compare(newProps: Props): boolean {
		return JSON.stringify(this.state) !== JSON.stringify(newProps)
			? true
			: false
	}

	setStateNStore<K extends keyof State>(newParams: Pick<State, K>): void {
		let newState: State = Object.assign({}, this.state, newParams)
		if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
			//console.info('Update: %c' + this.constructor.name, 'color: cyan;');
			this.setState(newState)
		}
	}

	setStateNStoreForce<K extends keyof State>(
		newParams: Pick<State, K>
	): void {
		let newState: State = Object.assign({}, this.state, newParams)
		//console.info('Update: %c' + this.constructor.name, 'color: cyan;');
		this.setState(newState)
	}
}

export default Main
