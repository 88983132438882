import Observer from '../observerstore/Observer'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import React from 'react'
import Store from '../observerstore/Store'
const $ = Store.data

interface Props {}
interface State {
	view: number
}

export default class LayoutButtons extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			view: 1,
		}
		this.click = this.click.bind(this)
	}

	update() {
		this.setStateNStore({
			view: $.view,
		})
	}

	click(e: any) {
		switch (e) {
			case 1:
				$.view = 1
				Store.notify()
				break
			case 2:
				$.view = 2
				Store.notify()
				break
		}
	}

	clickAPI() {
		//$.product.readAPI();
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<ToggleButtonGroup
					type="radio"
					name="options"
					defaultValue={this.state.view}
					onChange={this.click}
				>
					<ToggleButton value={1} size="sm" variant="outline-primary">
						<svg
							x="0px"
							y="0px"
							style={{ marginTop: '-3.5px' }}
							width="18"
							height="18"
							fill="currentColor"
							viewBox="0 0 13.4 15"
						>
							<rect width="3.8" height="3.8" />
							<rect x="4.8" width="3.8" height="3.8" />
							<rect x="9.7" width="3.8" height="3.8" />
							<g>
								<rect y="5.6" width="3.8" height="3.8" />
								<rect
									x="4.8"
									y="5.6"
									width="3.8"
									height="3.8"
								/>
								<rect
									x="9.7"
									y="5.6"
									width="3.8"
									height="3.8"
								/>
							</g>
							<g>
								<rect y="11.3" width="3.8" height="3.8" />
								<rect
									x="4.8"
									y="11.3"
									width="3.8"
									height="3.8"
								/>
								<rect
									x="9.7"
									y="11.3"
									width="3.8"
									height="3.8"
								/>
							</g>
						</svg>
					</ToggleButton>
					<ToggleButton value={2} size="sm" variant="outline-primary">
						<svg
							x="0px"
							y="0px"
							style={{ marginTop: '-3px' }}
							width="20"
							height="20"
							fill="currentColor"
							viewBox="0 0 13.4 15"
						>
							<rect y="5" width="13.4" height="10" />
							<g>
								<rect width="3.8" height="3.8" />
								<rect x="4.8" width="3.8" height="3.8" />
								<rect x="9.7" width="3.8" height="3.8" />
							</g>
						</svg>
					</ToggleButton>
				</ToggleButtonGroup>
			</React.Fragment>
		)
	}
}
