import Picture from '../interfaces/Picture'
import SealApi from '../interfaces/SealApi'
import { APIURL } from '../constants/urls'
import { HtmlElement } from '../componentsFunctional/BottomHtmlElement'

export default class Seal implements HtmlElement {
	id: number = 0
	name: string = ''
	description: string = ''

	translateApi(json: any) {
		const input: SealApi = json as SealApi
		this.id = input.id
		this.name = input.name
		this.description = input.description
	}

	getPicturePath(): Picture {
		const bildpfad = `${APIURL}/image/seals/CHE/${this.name}/${this.name}_`
		return {
			original: bildpfad + 'original.jpg',
			thumbnail: bildpfad + 'Xx100.jpg',
			small: bildpfad + 'Xx200.jpg',
			medium: bildpfad + 'Xx600.jpg',
			large: bildpfad + 'Xx1600.jpg',
		}
	}
}
