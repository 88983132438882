import Observer from '../../observerstore/Observer'
import Table from 'react-bootstrap/Table'
import styleDate from '../../functions/styleDate'
import React from 'react'
import Group from '../../models/Group'
import { Language } from '../../types/Language'
import Store from '../../observerstore/Store'
const $ = Store.data

interface Props {
	group: Group
}

interface State {
	language: Language
}

export default class InfoTable3 extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			language: $.language,
		}
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				{(this.props.group.collection.length > 1 ||
					this.props.group.collection[0].ausgabetag !== '') && (
					<Table
						hover
						responsive
						size="sm"
						borderless={true}
						style={{ marginTop: '22px', lineHeight: '0.9' }}
					>
						{this.props.group.collection.length > 1 && (
							<thead>
								<tr>
									<th className="text-left">
										{'Nr. ' +
											this.props.group.defineGroupNr() +
											', Satz: ' +
											this.props.group.getValueCount()}
									</th>
									<th className="text-right th-price">
										{this.props.group.calculatePrice(
											'postfrischViererblock'
										)}
									</th>
									<th className="text-right th-price">
										{this.props.group.calculatePrice(
											'gestempeltViererblock'
										)}
									</th>
									<th className="text-right th-price">
										{this.props.group.calculatePrice(
											'postfrisch'
										)}
									</th>
									<th className="text-right th-price">
										{this.props.group.calculatePrice(
											'gestempelt'
										)}
									</th>
									<th className="text-right th-price">
										{this.props.group.calculatePrice(
											'brief'
										)}
									</th>
								</tr>
							</thead>
						)}
						{this.props.group.collection[0].ausgabetag !== '' && (
							<tbody>
								<tr style={{ marginTop: '-20px' }}>
									<td colSpan={6} className="text-left">
										{'Ersttag: ' +
											styleDate(
												this.state.language,
												this.props.group.getIssueDate()
											)}
									</td>
								</tr>
								<tr>
									<td className="text-left">{'deutsch'}</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'viererblockEtDe'
										)}
									</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'vollstempelEtDe'
										)}
									</td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'fdcDe'
										)}
									</td>
								</tr>
								<tr>
									<td className="text-left">
										{'französisch'}
									</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'viererblockEtFr'
										)}
									</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'vollstempelEtFr'
										)}
									</td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'fdcFr'
										)}
									</td>
								</tr>
								<tr>
									<td className="text-left">
										{'italienisch'}
									</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'viererblockEtIt'
										)}
									</td>
									<td className="text-right th-price"></td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'vollstempelEtIt'
										)}
									</td>
									<td className="text-right th-price">
										{this.props.group.getGroupPrice(
											'fdcIt'
										)}
									</td>
								</tr>
							</tbody>
						)}
					</Table>
				)}
			</React.Fragment>
		)
	}
}
