import pictoZweierBlock from '../img/pictos/Picto_2er_Block_weiss.svg'
import pictoUngebraucht from '../img/pictos/Picto_ungebraucht_weiss.svg'
import pictoGestempelt from '../img/pictos/Picto_gestempelt_weiss.svg'
import pictoBrief from '../img/pictos/Picto_Brief_weiss.svg'
import pictoPostfrisch from '../img/pictos/Picto_postfrisch_weiss.svg'
import pictoViererBlock from '../img/pictos/Picto_4er_Block_weiss.svg'

export const PRICECATEGORIES: any = [
	{
		pictures: [
			{
				alt: 'Gestempelt',
				src: pictoGestempelt,
			},
		],
		price: 'gestempelt',
	},
	{
		pictures: [
			{
				alt: 'Ungestempelt',
				src: pictoUngebraucht,
			},
		],
		price: 'ungestempelt',
	},
	{
		pictures: [
			{
				alt: 'Postfrisch',
				src: pictoPostfrisch,
			},
		],
		price: 'postfrisch',
	},
	{
		pictures: [
			{
				alt: 'Brief',
				src: pictoBrief,
			},
		],
		price: 'brief',
	},
	{
		pictures: [
			{
				alt: 'Gestempelt',
				src: pictoGestempelt,
			},
			{
				alt: 'Vierer-Block',
				src: pictoViererBlock,
			},
		],
		price: 'gestempeltViererblock',
	},
	{
		pictures: [
			{
				alt: 'Postfrisch',
				src: pictoPostfrisch,
			},
			{
				alt: 'Vierer-Block',
				src: pictoViererBlock,
			},
		],
		price: 'postfrischViererblock',
	},

	{
		pictures: [
			{
				alt: 'Gestempelt',
				src: pictoGestempelt,
			},
			{
				alt: 'Paar',
				src: pictoZweierBlock,
			},
		],
		price: 'paarGestempelt',
	},
	{
		pictures: [
			{
				alt: 'Ungestempelt',
				src: pictoUngebraucht,
			},
			{
				alt: 'Paar',
				src: pictoZweierBlock,
			},
		],
		price: 'paarUngestempelt',
	},
	{
		pictures: [
			{
				alt: 'Brief',
				src: pictoBrief,
			},
			{
				alt: 'Paar',
				src: pictoZweierBlock,
			},
		],
		price: 'paarBrief',
	},
]
export default PRICECATEGORIES
