import React from 'react'
import Pagination from '../componentsFunctional/Pagination'
import Store from '../observerstore/Store'
import Observer from '../observerstore/Observer'
const $ = Store.data

interface Props {}
interface State {
	anzahl: number
	seite: number
	categories: number[]
}

export default class Pagina extends Observer<Props, State> {
	constructor(props: any) {
		super(props)
		this.state = {
			anzahl: 0,
			seite: 0,
			categories: [0],
		}
	}

	componentDidMount() {
		this.handleShortcuts(true)
		Store.attach(this)
	}

	componentWillUnmount() {
		this.handleShortcuts(false)
		Store.detach(this)
	}

	handleShortcuts(action: boolean) {
		if (action) {
			document.addEventListener('keyup', this.shortcut)
		} else {
			document.removeEventListener('keyup', this.shortcut)
		}
	}

	shortcut(e: any) {
		let button: any = ''
		switch (e.code) {
			case 'ArrowLeft':
				button = document.getElementById('paginaPrev')
				if (button !== null) button.click()
				break
			case 'ArrowRight':
				button = document.getElementById('paginaNext')
				if (button !== null) button.click()
				break
		}
	}

	private getCurrentCategoryNumber(input: number[]): number {
		if (input.length > 1) {
			return 1
		} else {
			return input[0]
		}
	}

	update() {
		this.setStateNStore({
			anzahl: $.url.anzahl,
			seite: $.url.seite,
			categories: $.url.categories,
		})
	}

	getCountOfAllListItems(): number {
		return $.groupCollection.count
	}

	render() {
		let categoryId = this.getCurrentCategoryNumber(this.state.categories)
		if (!this.state.anzahl) return <></>
		return (
			<Pagination
				countOfAllListItems={this.getCountOfAllListItems()}
				pageIndex={this.state.seite - 1}
				setPageIndex={(input: number) => {
					window.location.href = `#/overview/${categoryId}/${
						this.state.anzahl
					}/${input + 1}`
				}}
				listItemAmount={this.state.anzahl}
				maxNumberOfButtons={5}
			/>
		)
	}
}
