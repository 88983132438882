import Detail from '../models/Detail'

export default class DetailList {
	all: Detail[] = []

	constructor() {
		this.all = []
	}

	translateApi(json: any) {
		if (json !== undefined) {
			this.all = json.map((p: any) => {
				let detail: Detail = new Detail()
				detail.translateApi(p)
				return detail
			})
		}
	}

	getAll(): Detail[] {
		return this.all
	}
}
