import * as apiLogin from '../api/Login/LoginPost'
import { setToken } from '../functions/token'
import { getToken } from '../functions/token'
import { parseJwt } from '../functions/token'
import UserApi from '../interfaces/UserApi'

export type propsString = 'username' | 'firstname' | 'lastname' | 'email'
export type propsNumber = 'id' | 'number'
export type propsBoolean = 'isAdmin'

export default class User {
	public id: number | undefined = undefined
	public username: string = ''
	public password: string = ''
	public number: number = 0
	public firstname: string = ''
	public lastname: string = ''
	public email: string = ''
	public isAdmin: boolean = false
	selected: boolean = false

	public constructor(input?: UserApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public async login(): Promise<boolean> {
		const response: apiLogin.ResponseData | null = await apiLogin.apiCall(
			this.username,
			this.password
		)
		if (response) {
			setToken(response.token)
			return true
		}
		return false
	}

	public mapToApi(): UserApi {
		return {
			id: this.id,
			username: this.username,
			number: this.number,
			firstname: this.firstname,
			lastname: this.lastname,
			isAdmin: this.isAdmin,
			email: this.email,
			password: this.password,
		}
	}

	public mapJwtToThis() {
		const token: string | null = getToken()
		if (token) {
			const userApi: UserApi = parseJwt(token)
			this.mapApiToThis(userApi)
		}
	}

	public mapApiToThis(input: UserApi) {
		this.id = input.id
		this.username = input.username
		this.number = input.number
		this.firstname = input.firstname
		this.lastname = input.lastname
		this.isAdmin = input.isAdmin
		this.email = input.email
	}
}
