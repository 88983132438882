let apiurl = 'https://api.rj-catalog.ch'
const hostname = window.location.hostname

if (hostname.includes('test.rj-catalog')) {
	apiurl = 'https://test-api.rj-catalog.ch'
}
if (hostname.includes('localhost')) {
	apiurl = 'http://localhost'
}

export const APIURL = apiurl
export const REQUESTURL = `${apiurl}/`
