import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Product from '../models/Product'
import GroupCardBottomDetail from '../components/GroupCardBottomDetail'
import Group from '../models/Group'
import GroupDetailPicture from '../components/GroupDetailPicture'
import { Language } from '../types/Language'
import PricesGroups1 from '../layouts/pricesGroups/PricesGroups1'
import PricesGroups2 from '../layouts/pricesGroups/PricesGroups2'
import PricesGroups3 from '../layouts/pricesGroups/PricesGroups3'
import PricesGroups5 from '../layouts/pricesGroups/PricesGroups5'
import PricesGroups7 from '../layouts/pricesGroups/PricesGroups7'
import PricesGroups8 from '../layouts/pricesGroups/PricesGroups8'
import PricesGroups9 from '../layouts/pricesGroups/PricesGroups9'
import PricesGroups10 from '../layouts/pricesGroups/PricesGroups10'
import Observer from '../observerstore/Observer'
import Store from '../observerstore/Store'
const $ = Store.data

interface Props {
	group: Group
}
interface State {
	bilder: string[]
	language: Language
}

export default class GroupDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			bilder: [],
			language: $.language,
		}

		this.previousProduct = this.previousProduct.bind(this)
		this.nextProduct = this.nextProduct.bind(this)
		this.navigateBack = this.navigateBack.bind(this)
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	previousProduct() {
		let product: Product | undefined = this.props.group.getLastProduct()
		if (product !== undefined) {
			let url = '#/detail/' + product.kategorie_id + '/' + product.znr
			window.location.href = url
		}
	}

	nextProduct() {
		let nextProduct: Product | undefined =
			this.props.group.getFirstProduct()
		if (nextProduct !== undefined) {
			let url =
				'#/detail/' + nextProduct.kategorie_id + '/' + nextProduct.znr
			window.location.href = url
		}
	}

	private getCurrentCategoryNumber(input: number[]): number {
		if (input.length > 1) {
			return 1
		} else {
			return input[0]
		}
	}

	navigateBack(): void {
		let url: string = '#/overview/'
		let currentCategory = this.getCurrentCategoryNumber($.url.categories)
		url += currentCategory + '/'
		url += $.url.anzahl + '/'
		url += $.url.seite + '/'
		window.location.href = url
	}

	renderPrices(): React.ReactNode {
		if (this.props.group !== undefined) {
			switch (this.props.group.getLayoutType()) {
				case 2:
					return <PricesGroups2 group={this.props.group} />
				case 3:
					return <PricesGroups3 group={this.props.group} />
				case 5:
					return <PricesGroups5 group={this.props.group} />
				case 7:
					return <PricesGroups7 group={this.props.group} />
				case 8:
					return <PricesGroups8 group={this.props.group} />
				case 9:
					return <PricesGroups9 group={this.props.group} />
				case 10:
					return <PricesGroups10 group={this.props.group} />
				default:
					return <PricesGroups1 group={this.props.group} />
			}
		} else {
			return ''
		}
	}

	render(): React.ReactNode {
		if (this.props.group !== undefined) {
			//const images = this.imagesVerarbeiten(this.props.product);
			return (
				<>
					<div id="main">
						<div className="backcross" onClick={this.navigateBack}>
							&#215;
						</div>
						<div id="left" onClick={this.previousProduct}>
							<svg
								fill="currentColor"
								width="20"
								height="20"
								x="0px"
								y="0px"
								viewBox="0 0 13.3 15"
							>
								<path d="M8.9,1.3L7.5,0L0,7.5L7.5,15l1.3-1.3L2.7,7.5L8.9,1.3z" />
							</svg>
						</div>
						<article>
							<code className="component_info">
								{this.constructor.name}
							</code>
							<Row>
								<Col sm={6} className="detail">
									<GroupDetailPicture
										group={this.props.group}
									/>
								</Col>
								<Col sm={6} className="detail">
									<div className="set-card-title">
										<h1>
											<span className="year">
												{this.props.group.getYear()}
											</span>
											&nbsp;&nbsp;
											{this.props.group.getTitle(
												this.state.language
											)}
										</h1>
										<p>
											{this.props.group.getDescription(
												this.state.language
											)}
										</p>
									</div>
									<hr className="zero-line" />
									<p>
										<span className="mute">
											CHE
											{' ' +
												this.props.group.getHauserZusatz()}
										</span>{' '}
										{' ' + this.props.group.getZnr()}
										<br />
										{this.props.group.getDescription(
											this.state.language
										)}
									</p>
									<hr className="zero-line" />
									<p>
										{'Ausgabedatum: ' +
											this.props.group.getYear()}
									</p>
									<hr className="zero-line" />
									{this.renderPrices()}
								</Col>
							</Row>
						</article>
						<div id="right" onClick={this.nextProduct}>
							<svg
								fill="currentColor"
								width="20"
								height="20"
								x="0px"
								y="0px"
								viewBox="0 0 13.3 15"
							>
								<path d="M0,1.3L1.3,0l7.5,7.5L1.3,15L0,13.7l6.2-6.2L0,1.3z" />
							</svg>
						</div>
					</div>
					<GroupCardBottomDetail input={this.props.group} />
				</>
			)
		} else {
			return 'Produkte wurden nicht gefunden'
		}
	}
}
