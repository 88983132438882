import Observer from '../../observerstore/Observer'
import pictoPostfrisch from '../../img/pictos/Picto_postfrisch_weiss.svg'
import pictoGestempelt from '../../img/pictos/Picto_gestempelt_weiss.svg'
import pictoBrief from '../../img/pictos/Picto_Brief_weiss.svg'
import React from 'react'
import ParentProduct from '../../models/ParentProduct'
import Price from '../../interfaces/Price'

interface Props {
	product: ParentProduct
}
interface State {
	price: boolean
	detail: boolean
}

export default class PricesProducts2 extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			price: false,
			detail: false,
		}
		this.click = this.click.bind(this)
	}

	click(_e: any) {
		this.setState({
			price: !this.state.price,
		})
	}

	mouseEnter(_productId: number) {}

	mouseLeave() {}

	renderPrice<K extends keyof Price>(input: K): JSX.Element {
		let price: string = this.props.product.getPrice(input)

		return (
			<React.Fragment>
				{price !== '–' && price !== 'LP' && (
					<span className="text-left">CHF</span>
				)}
				<span className="float-right">{price}</span>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<table className="prices-table">
					<tbody>
						<tr>
							<th className="text-right">
								<img
									alt="Gestempelt"
									src={pictoGestempelt}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('gestempelt')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Postfrisch"
									src={pictoPostfrisch}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('postfrisch')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('brief')}</td>
						</tr>
						<tr
							onMouseEnter={() =>
								this.mouseEnter(this.props.product.id)
							}
							onMouseLeave={this.mouseLeave}
						></tr>
					</tbody>
				</table>
			</React.Fragment>
		)
	}
}
