import Login from './views/Login/Login'
import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import ErrorView from './views/ErrorView'
import Unternehmen from './views/Unternehmen'
import Leitbild from './views/Leitbild'
import Kontakt from './views/Kontakt'
import Overview from './views/Overview'
import Detail from './views/Detail'
import UnderConstruction from './views/UnderConstruction'
import Verkauf from './views/Verkauf'
import Datenschutz from './views/Datenschutz'
import Impressum from './views/Impressum'
import AGB from './views/AGB'
import Unterstuetzung from './views/Unterstuetzung'

function App() {
	let underConstruction: string[] = [
		'liechtenstein',
		'deutschland',
		'frankreich',
		'oesterreich',
		'england',
		'usa',
	]

	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Overview} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/unternehmen" component={Unternehmen} />
				<Route exact path="/leitbild" component={Leitbild} />
				<Route exact path="/kontakt" component={Kontakt} />
				<Route
					exact
					path="/overview/:group/:anzahl/:seite/:level?"
					component={Overview}
				/>
				<Route
					exact
					path="/detail/:katId/:znr/:group?"
					component={Detail}
				/>
				<Route exact path="/verkaufen" component={Verkauf} />
				<Route exact path="/datenschutz" component={Datenschutz} />
				<Route exact path="/impressum" component={Impressum} />
				<Route exact path="/agb" component={AGB} />
				<Route
					exact
					path="/unterstuetzung"
					component={Unterstuetzung}
				/>
				{underConstruction.map((p, i) => (
					<Route
						exact
						key={i}
						path={'/' + p}
						component={UnderConstruction}
					/>
				))}
				<Route component={ErrorView} />
			</Switch>
		</Router>
	)
}

export default App
