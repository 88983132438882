import Fdc from '../models/Fdc'

export default class FdcList {
	all: Fdc[] = []

	constructor() {
		this.all = []
	}

	translateApi(json: any) {
		if (json !== undefined) {
			this.all = json.map((p: any) => {
				let fdc: Fdc = new Fdc()
				fdc.translateApi(p)
				return fdc
			})
		}
	}

	getAll(): Fdc[] {
		return this.all
	}
}
