import React from 'react'

import '../css/letter.css'
import Col from 'react-bootstrap/Col'
import Group from '../models/Group'
import ImageGallery from 'react-image-gallery'
import Picture from '../interfaces/Picture'
import Product from '../models/Product'
import Row from 'react-bootstrap/Row'

interface ImageGalleryItem {
	original: string
	thumbnail: string
	originalAlt: string
	thumbnailAlt: string
	originalTitle: string
	thumbnailTitle: string
}

export interface HtmlElement {
	id: number
	name: string
	description: string
	translateApi: (json: any) => void
	getPicturePath: () => Picture
}

interface Props {
	input: Group | Product
	productKeyName: 'sealList' | 'detailList' | 'probationList' | 'fdcList'
}

export default function BottomHtmlElement(props: Props): JSX.Element {
	function getElementsFromGroup(input: Group): HtmlElement[] {
		let products: Product[] = input.collection

		let all: HtmlElement[] = []
		let single: HtmlElement[] = []
		let multi: HtmlElement[] = []

		// Alle Produkte suchen
		for (let p of products) {
			for (let l of p[props.productKeyName].getAll()) {
				all.push(l)
			}
		}

		// Zwischen Multi und Single-Stamp unterscheiden
		for (let i of all) {
			let temp = all.filter((l) => i.id === l.id)
			if (temp.length === 1) {
				single.push(i)
			} else {
				let tempBool: Boolean = true
				for (let z of multi) {
					if (i.id === z.id) {
						tempBool = false
					}
				}
				if (tempBool) multi.push(i)
			}
		}

		return single.concat(multi)
	}

	function getElementsFromProduct(input: Product): HtmlElement[] {
		return input[props.productKeyName].all
	}

	function getElementPicture(element: HtmlElement): ImageGalleryItem[] {
		const images: ImageGalleryItem[] = []
		images.push({
			original: element.getPicturePath().large,
			thumbnail: element.getPicturePath().thumbnail,
			originalAlt: `Briefmarke: ${element.name}`,
			thumbnailAlt: `Briefmarke: ${element.name}`,
			originalTitle: element.name,
			thumbnailTitle: element.name,
		})
		return images
	}

	function getElements(): HtmlElement[] {
		if (props.input instanceof Group) {
			return getElementsFromGroup(props.input)
		} else {
			return getElementsFromProduct(props.input)
		}
	}

	let elements: HtmlElement[] = getElements()

	return (
		<div>
			<code className="component_info">Elements</code>
			{elements.map((l, i) => (
				<div className="letterbox letterbox-font" key={i}>
					<Row>
						<Col xs={12} md={6}>
							<ImageGallery
								items={getElementPicture(l)}
								showPlayButton={false}
								showThumbnails={false}
							/>
						</Col>
						<Col xs={6} md={6}>
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: l.description,
								}}
							></div>
						</Col>
					</Row>
				</div>
			))}
		</div>
	)
}
