import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import React from 'react'

import T from '../language/GroupCardBottom.json'
import BottomHtmlElement from '../componentsFunctional/BottomHtmlElement'
import Colors from '../components/Colors'
import Group from '../models/Group'
import Letters from '../components/Letters'
import Observer from '../observerstore/Observer'
import Prices1 from '../layouts/prices/Prices1'
import Prices10 from '../layouts/prices/Prices10'
import Prices2 from '../layouts/prices/Prices2'
import Prices3 from '../layouts/prices/Prices3'
import Prices4 from '../layouts/prices/Prices4'
import Prices5 from '../layouts/prices/Prices5'
import Prices6 from '../layouts/prices/Prices6'
import Prices7 from '../layouts/prices/Prices7'
import Prices8 from '../layouts/prices/Prices8'
import Prices9 from '../layouts/prices/Prices9'
import Store from '../observerstore/Store'
import Varieties from '../components/Varieties'
import { Language } from '../types/Language'

const $ = Store.data

interface Props {
	group: Group
}
interface State {
	price: boolean
	detail: boolean
	colors: boolean
	variety: boolean
	seals: boolean
	probation: boolean
	fakes: boolean
	letter: boolean
	fdc: boolean
	language: Language
}

interface ButtonInformation {
	id: keyof State
	disabled: boolean
	name: string
}

export default class GroupCardBottom extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			price: $.view === 1 ? false : true,
			detail: false,
			colors: false,
			variety: false,
			seals: false,
			probation: false,
			fakes: false,
			letter: false,
			fdc: false,
			language: $.language,
		}
		this.click = this.click.bind(this)
	}

	public update(): void {
		this.setStateNStore({
			price: $.view === 1 ? false : true,
			language: $.language,
		})
	}

	private click<K extends keyof State>(event: any): void {
		let key: K = event.target.id
		let viewBool: boolean = !this.state[key]
		let newState: any = {
			price: false,
			detail: false,
			colors: false,
			variety: false,
			seals: false,
			probation: false,
			letter: false,
			fdc: false,
		}
		newState[key] = viewBool
		this.setState(newState)
	}

	private renderPrices(): React.ReactNode {
		if (this.state.price === true) {
			switch (this.props.group.getLayoutType()) {
				case 2:
					return (
						<Prices2
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 3:
					return (
						<Prices3
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 4:
					return (
						<Prices4
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 5:
					return (
						<Prices5
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 6:
					return (
						<Prices6
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 7:
					return (
						<Prices7
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 8:
					return (
						<Prices8
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 9:
					return (
						<Prices9
							group={this.props.group}
							language={this.state.language}
						/>
					)
				case 10:
					return (
						<Prices10
							group={this.props.group}
							language={this.state.language}
						/>
					)
				default:
					return (
						<Prices1
							group={this.props.group}
							language={this.state.language}
						/>
					)
			}
		} else {
			return ''
		}
	}

	private getButtonDetails(): ButtonInformation[] {
		return [
			{
				id: 'price',
				disabled: false,
				name: T.price[this.state.language],
			},
			{
				id: 'detail',
				disabled: !this.props.group.hasDetails(),
				name: T.detail[this.state.language],
			},
			{
				id: 'colors',
				disabled: !this.props.group.hasColors(),
				name: T.colors[this.state.language],
			},
			{
				id: 'variety',
				disabled: !this.props.group.hasVarieties(),
				name: T.variety[this.state.language],
			},
			{
				id: 'seals',
				disabled: !this.props.group.hasSeals(),
				name: T.seals[this.state.language],
			},
			{
				id: 'probation',
				disabled: !this.props.group.hasProbations(),
				name: T.probations[this.state.language],
			},
			{
				id: 'fakes',
				disabled: true,
				name: T.fakes[this.state.language],
			},
			{
				id: 'letter',
				disabled: !this.props.group.hasLetters(),
				name: T.letter[this.state.language],
			},
			{
				id: 'fdc',
				disabled: !this.props.group.hasFdcs(),
				name: T.fdc[this.state.language],
			},
		]
	}

	public render() {
		let buttons = this.getButtonDetails()

		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<br />
				<ButtonGroup
					aria-label="Basic example"
					style={{ marginTop: '10px' }}
				>
					{buttons.map((b, i: number) => (
						<Button
							key={i}
							id={b.id}
							variant="outline-primary"
							onClick={this.click}
							size="sm"
							active={this.state[b.id] !== false}
							disabled={b.disabled}
						>
							{b.name}
						</Button>
					))}
				</ButtonGroup>
				<br />
				<div className="groupcard-bottom-info">
					{this.renderPrices()}
					{this.state.letter === true && (
						<Letters input={this.props.group} />
					)}
					{this.state.detail === true && (
						<BottomHtmlElement
							input={this.props.group}
							productKeyName={'detailList'}
						/>
					)}
					{this.state.variety === true && (
						<Varieties input={this.props.group} />
					)}
					{this.state.colors === true && (
						<Colors input={this.props.group} />
					)}
					{this.state.seals === true && (
						<BottomHtmlElement
							input={this.props.group}
							productKeyName={'sealList'}
						/>
					)}
					{this.state.probation === true && (
						<BottomHtmlElement
							input={this.props.group}
							productKeyName={'probationList'}
						/>
					)}
					{this.state.fdc === true && (
						<BottomHtmlElement
							input={this.props.group}
							productKeyName={'fdcList'}
						/>
					)}
				</div>
			</React.Fragment>
		)
	}
}
