import Observer from '../../observerstore/Observer'
import pictoPostfrisch from '../../img/pictos/Picto_postfrisch_weiss.svg'
import pictoViererBlock from '../../img/pictos/Picto_4er_Block_weiss.svg'
import pictoGestempelt from '../../img/pictos/Picto_gestempelt_weiss.svg'
import pictoBrief from '../../img/pictos/Picto_Brief_weiss.svg'
import pictoVollstempel from '../../img/pictos/Picto_Vollstempel_weiss.svg'

import React from 'react'
import Group from '../../models/Group'
import Price from '../../interfaces/Price'

interface Props {
	group: Group
}
interface State {
	price: boolean
	detail: boolean
}

export default class PricesGroups3 extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			price: false,
			detail: false,
		}
		this.click = this.click.bind(this)
	}

	click(e: any) {
		this.setState({
			price: !this.state.price,
		})
	}

	mouseEnter(_productId: number) {}

	mouseLeave() {}

	renderPrice<K extends keyof Price>(input: K): JSX.Element {
		let price: string = this.props.group.calculatePrice(input)

		return (
			<React.Fragment>
				{price !== '–' && price !== 'LP' && (
					<span className="text-left">CHF</span>
				)}
				<span className="float-right">{price}</span>
			</React.Fragment>
		)
	}

	// Diese Funktion sollte irgendwann von der Funktion oberhalb abgelöst werden
	renderPriceSpecial<K extends keyof Price>(input: K): JSX.Element {
		let price: string = this.props.group.calculatePriceSpecial(input)

		return (
			<React.Fragment>
				{price !== '–' && price !== 'LP' && (
					<span className="text-left">CHF</span>
				)}
				<span className="float-right">{price}</span>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<table className="prices-table">
					<tbody>
						<tr>
							<th className="text-right">
								<img
									alt="Gestempelt"
									src={pictoGestempelt}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('gestempelt')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Postfrisch"
									src={pictoPostfrisch}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('postfrisch')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('brief')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Gestempelt"
									src={pictoGestempelt}
									className="pictogram"
								/>
								<img
									alt="Vierer-Block"
									src={pictoViererBlock}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('gestempeltViererblock')}</td>
						</tr>
						<tr>
							<th className="text-right">
								<img
									alt="Postfrisch"
									src={pictoPostfrisch}
									className="pictogram"
								/>
								<img
									alt="Vierer-Block"
									src={pictoViererBlock}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPrice('postfrischViererblock')}</td>
						</tr>
						<tr
							onMouseEnter={() =>
								this.mouseEnter(this.props.group.count)
							}
							onMouseLeave={this.mouseLeave}
						></tr>
					</tbody>
				</table>

				<table className="prices-table" style={{ marginTop: '10px' }}>
					<tbody>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPriceSpecial('fdcDe')}</td>
							<td className="mute">deutsch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vollstempel"
									src={pictoVollstempel}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('vollstempelEtDe')}
							</td>
							<td className="mute">deutsch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vierer-Block"
									src={pictoViererBlock}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('viererblockEtDe')}
							</td>
							<td className="mute">deutsch</td>
						</tr>
					</tbody>
				</table>
				<table className="prices-table" style={{ marginTop: '10px' }}>
					<tbody>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPriceSpecial('fdcFr')}</td>
							<td className="mute">französisch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vollstempel"
									src={pictoVollstempel}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('vollstempelEtFr')}
							</td>
							<td className="mute">französisch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vierer-Block"
									src={pictoViererBlock}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('viererblockEtFr')}
							</td>
							<td className="mute">französisch</td>
						</tr>
					</tbody>
				</table>
				<table className="prices-table" style={{ marginTop: '10px' }}>
					<tbody>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
							<td>{this.renderPriceSpecial('fdcIt')}</td>
							<td className="mute">italienisch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vollstempel"
									src={pictoVollstempel}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('vollstempelEtIt')}
							</td>
							<td className="mute">italienisch</td>
						</tr>
						<tr>
							<th className="text-right">
								ET
								<img
									alt="Vierer-Block"
									src={pictoViererBlock}
									className="pictogram"
								/>
							</th>
							<td>
								{this.renderPriceSpecial('viererblockEtIt')}
							</td>
							<td className="mute">italienisch</td>
						</tr>
					</tbody>
				</table>
			</React.Fragment>
		)
	}
}
