class Contact {
	salutation: string
	firm: string
	forename: string
	name: string
	street: string
	number: string
	plz: string
	place: string
	eMail: string
	telefon: string

	constructor() {
		this.salutation = ''
		this.firm = ''
		this.forename = ''
		this.name = ''
		this.street = ''
		this.number = ''
		this.plz = ''
		this.place = ''
		this.eMail = ''
		this.telefon = ''
	}
}
export default Contact
