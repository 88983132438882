import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import React from 'react'

import '../css/letter.css'
import * as T from '../language/GroupCardBottomDetail'
import BottomHtmlElement from '../componentsFunctional/BottomHtmlElement'
import Colors from '../components/Colors'
import Group from '../models/Group'
import Letters from '../components/Letters'
import Observer from '../observerstore/Observer'
import Product from '../models/Product'
import Store from '../observerstore/Store'
import Varieties from '../components/Varieties'
import { Language } from '../types/Language'

const $ = Store.data

interface Props {
	input: Group | Product
}

interface State {
	detail: boolean
	colors: boolean
	variety: boolean
	seals: boolean
	probation: boolean
	fdc: boolean
	fakes: boolean
	letter: boolean
	language: Language
}

interface ButtonInformation {
	id: keyof State
	disabled: boolean
	name: string
}

export default class GroupCardBottomDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			detail: false,
			colors: false,
			variety: false,
			seals: false,
			probation: false,
			fdc: false,
			fakes: false,
			letter: false,
			language: $.language,
		}
		this.click = this.click.bind(this)
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	getButtonInformations(): ButtonInformation[] {
		return [
			{
				id: 'detail',
				disabled: !this.props.input.hasDetails(),
				name: T.details(this.state.language),
			},
			{
				id: 'colors',
				disabled: !this.props.input.hasColors(),
				name: T.colors(this.state.language),
			},
			{
				id: 'variety',
				disabled: !this.props.input.hasVarieties(),
				name: T.variety(this.state.language),
			},
			{
				id: 'seals',
				disabled: !this.props.input.hasSeals(),
				name: T.seals(this.state.language),
			},
			{
				id: 'probation',
				disabled: !this.props.input.hasProbations(),
				name: T.probations(this.state.language),
			},
			{
				id: 'fakes',
				disabled: true,
				name: T.fakes(this.state.language),
			},
			{
				id: 'letter',
				disabled: !this.props.input.hasLetters(),
				name: T.letter(this.state.language),
			},
			{
				id: 'fdc',
				disabled: !this.props.input.hasFdcs(),
				name: T.fdc(this.state.language),
			},
		]
	}

	click<K extends keyof State>(event: any): void {
		let key: K = event.target.id
		let viewBool: boolean = !this.state[key]
		let newState: any = {
			detail: false,
			colors: false,
			variety: false,
			seals: false,
			probation: false,
			fdc: false,
			fakes: false,
			letter: false,
		}
		newState[key] = viewBool
		this.setState(newState)
	}

	render() {
		return (
			<React.Fragment>
				<div className="letterbox-detail">
					<code className="component_info">
						{this.constructor.name}
					</code>
					<ButtonGroup
						aria-label="Basic example"
						style={{ marginTop: '10px' }}
						className="d-flex"
					>
						{this.getButtonInformations().map((b, i: number) => (
							<Button
								key={i}
								id={b.id}
								variant="outline-primary"
								onClick={this.click}
								size="sm"
								active={this.state[b.id] !== false}
								disabled={b.disabled}
							>
								{b.name}
							</Button>
						))}
					</ButtonGroup>
					<div className="groupcard-bottom-info">
						{this.state.letter === true && (
							<Letters input={this.props.input} />
						)}
						{this.state.detail === true && (
							<BottomHtmlElement
								input={this.props.input}
								productKeyName={'detailList'}
							/>
						)}
						{this.state.variety === true && (
							<Varieties input={this.props.input} />
						)}
						{this.state.colors === true && (
							<Colors input={this.props.input} />
						)}
						{this.state.seals === true && (
							<BottomHtmlElement
								input={this.props.input}
								productKeyName={'sealList'}
							/>
						)}
						{this.state.probation === true && (
							<BottomHtmlElement
								input={this.props.input}
								productKeyName={'probationList'}
							/>
						)}
						{this.state.fdc === true && (
							<BottomHtmlElement
								input={this.props.input}
								productKeyName={'fdcList'}
							/>
						)}
					</div>
				</div>
			</React.Fragment>
		)
	}
}
