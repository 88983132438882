import Observer from '../observerstore/Observer'
import React from 'react'
import Group from '../models/Group'
import ColorDetail from '../components/ColorDetail'
import Product from '../models/Product'
import Color from '../models/Color'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../css/letter.css'

interface Props {
	input: Group | Product
}
interface State {
	detail: boolean
}

export default class Colors extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			detail: false,
		}
	}

	getColorsFromGroup(input: Group): Color[] {
		let products: Product[] = input.collection

		let all: Color[] = []
		let single: Color[] = []
		let multi: Color[] = []

		// Alle Abarten suchen
		for (let p of products) {
			for (let l of p.colors.all()) {
				all.push(l)
			}
		}

		// Zwischen Multi und Single-Stamp unterscheiden
		for (let i of all) {
			let temp = all.filter((l) => i.id === l.id)
			if (temp.length === 1) {
				single.push(i)
			} else {
				let tempBool: Boolean = true
				for (let z of multi) {
					if (i.id === z.id) {
						tempBool = false
					}
				}
				if (tempBool) multi.push(i)
			}
		}

		return single.concat(multi)
	}

	getColorsFromProduct(input: Product): Color[] {
		return input.colors.collection
	}

	getColors(): Color[] {
		if (this.props.input instanceof Group) {
			return this.getColorsFromGroup(this.props.input)
		} else {
			return this.getColorsFromProduct(this.props.input)
		}
	}

	render() {
		let colors: Color[] = this.getColors()
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				{colors.map((c, i) => (
					<div className="letterbox letterbox-font" key={i}>
						<Row>
							<Col xs={12} md={12}>
								<ColorDetail color={c} />
							</Col>
						</Row>
					</div>
				))}
			</React.Fragment>
		)
	}
}
