import Observer from '../../observerstore/Observer'
import Table from 'react-bootstrap/Table'
import pictoUngebraucht from '../../img/pictos/Picto_ungebraucht_weiss.svg'
import pictoGestempelt from '../../img/pictos/Picto_gestempelt_weiss.svg'
import pictoBrief from '../../img/pictos/Picto_Brief_weiss.svg'
import InfoTable2 from '../infoTable/InfoTable2'
import * as T from '../../language/Prices'
import { Language } from '../../types/Language'
import React from 'react'
import Group from '../../models/Group'

interface Props {
	group: Group
	language: Language
}
interface State {
	price: boolean
	detail: boolean
}

export default class Prices9 extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			price: false,
			detail: false,
		}
		this.click = this.click.bind(this)
	}

	click(_e: any) {
		this.setState({
			price: !this.state.price,
		})
	}

	mouseEnter(_productId: number) {}

	mouseLeave() {}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<Table striped bordered hover responsive size="sm">
					<thead>
						<tr>
							<th
								className="text-center"
								style={{ width: '120px', minWidth: '120px' }}
							>
								{T.nr(this.props.language)}
							</th>
							<th
								className="text-right"
								style={{ width: '80px' }}
							>
								{T.rp(this.props.language)}
							</th>
							<th className="" style={{ minWidth: '150px' }}>
								{T.color(this.props.language)}
							</th>
							<th className="text-right th-price">
								<img
									alt="Ungestempelt"
									src={pictoUngebraucht}
									className="pictogram"
								/>
							</th>
							<th className="text-right th-price">
								<img
									alt="Gestempelt"
									src={pictoGestempelt}
									className="pictogram"
								/>
							</th>
							<th className="text-right th-price">
								<img
									alt="Brief"
									src={pictoBrief}
									className="pictogram"
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{this.props.group.collection.map((p, i) => (
							<tr
								key={i}
								onMouseEnter={() => this.mouseEnter(p.id)}
								onMouseLeave={this.mouseLeave}
							>
								<td className="text-center">{p.znr}</td>
								<td className="text-right">{p.wertstufe}</td>
								<td className="text-left">{p.farbe_de}</td>
								<td className="text-right">
									{p.getPrice('ungestempelt')}
								</td>
								<td className="text-right">
									{p.getPrice('gestempelt')}
								</td>
								<td className="text-right">
									{p.getPrice('brief')}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<InfoTable2 group={this.props.group} />
			</React.Fragment>
		)
	}
}
