import Observer from '../observerstore/Observer'
import React from 'react'
import Group from '../models/Group'
import VarietyDetail from '../components/VarietyDetail'
import Product from '../models/Product'
import Variety from '../models/Variety'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../css/letter.css'

interface Props {
	input: Group | Product
}
interface State {
	detail: boolean
}

export default class Varieties extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			detail: false,
		}
	}

	getVarietiesFromGroup(input: Group): Variety[] {
		let products: Product[] = input.collection

		let all: Variety[] = []
		let single: Variety[] = []
		let multi: Variety[] = []

		// Alle Abarten suchen
		for (let p of products) {
			for (let l of p.varieties.all()) {
				all.push(l)
			}
		}

		// Zwischen Multi und Single-Stamp unterscheiden
		for (let i of all) {
			let temp = all.filter((l) => i.id === l.id)
			if (temp.length === 1) {
				single.push(i)
			} else {
				let tempBool: Boolean = true
				for (let z of multi) {
					if (i.id === z.id) {
						tempBool = false
					}
				}
				if (tempBool) multi.push(i)
			}
		}

		return single.concat(multi)
	}

	getVarietiesFromProduct(input: Product): Variety[] {
		return input.varieties.collection
	}

	getVarieties(): Variety[] {
		if (this.props.input instanceof Group) {
			return this.getVarietiesFromGroup(this.props.input)
		} else {
			return this.getVarietiesFromProduct(this.props.input)
		}
	}

	render() {
		let varieties: Variety[] = this.getVarieties()
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				{varieties.map((v, i) => (
					<div className="letterbox letterbox-font" key={i}>
						<Row>
							<Col xs={12} md={12}>
								<VarietyDetail variety={v} />
							</Col>
						</Row>
					</div>
				))}
			</React.Fragment>
		)
	}
}
