import Seal from '../models/Seal'

export default class SealList {
	all: Seal[] = []

	constructor() {
		this.all = []
	}

	translateApi(json: any) {
		if (json !== undefined) {
			this.all = json.map((p: any) => {
				let seal: Seal = new Seal()
				seal.translateApi(p)
				return seal
			})
		}
	}

	getAll(): Seal[] {
		return this.all
	}
}
