import React from 'react'
import Alert from 'react-bootstrap/Alert'
import * as T from '../language/SearchBanner'
import Store from '../observerstore/Store'
import Observer from '../observerstore/Observer'
import { Language } from '../types/Language'

const $ = Store.data

interface Props {}

interface State {
	title: string
	count: number
	searchTerm: string
	language: Language
}

class SearchBanner extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			title: '',
			count: 0,
			searchTerm: '',
			language: $.language,
		}
	}

	update() {
		this.setStateNStore({
			title: $.categoryCollection.findTitle($.url.katId),
			count: $.groupCollection.count,
			searchTerm: $.url.searchTerm,
			language: $.language,
		})
	}

	checkSearch() {
		if ($.url.searchTerm === '') {
			return false
		}
		return true
	}

	resetSearch() {
		Object.assign($.url, { searchTerm: '' })
		$.groupCollection.readApi(
			Store,
			$.url.categories,
			$.url.anzahl,
			$.url.seite,
			$.url.level,
			$.url.sortAttribute,
			$.url.sortOrder,
			'',
			$.url.searchType
		)
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>

				{this.checkSearch() && (
					<Alert
						variant={
							Boolean(this.state.count) ? 'success' : 'danger'
						}
						className="suchbegriffInfo"
						dismissible
						onClose={() => this.resetSearch()}
					>
						{T.info(
							this.state.language,
							this.state.searchTerm,
							this.state.title,
							this.state.count
						)}
						&nbsp;&nbsp;|&nbsp;&nbsp;
						<Alert.Link onClick={this.resetSearch}>
							{T.resetSearch(this.state.language)}
						</Alert.Link>
					</Alert>
				)}
			</>
		)
	}
}

export default SearchBanner
