import Observer from '../observerstore/Observer'
import Product from '../models/Product'
import Image from 'react-bootstrap/Image'
import { Language } from '../types/Language'
import Store from '../observerstore/Store'
import Group from '../models/Group'
import React from 'react'
const $ = Store.data
//import Store from "../observerstore/Store";
//const $ = Store.data;

interface Props {
	group: Group
}
interface State {
	loading: boolean
	count: number
	language: Language
}

export default class GroupDetailPicture extends Observer<Props, State> {
	private constructor(props: Props) {
		super(props)
		this.state = {
			loading: true,
			count: 0,
			language: $.language,
		}
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageErrored = this.handleImageErrored.bind(this)
	}

	public update(): void {
		this.setStateNStore({
			language: $.language,
		})
	}

	private handleImageLoaded() {
		let count = this.state.count + 1
		this.setState({ count: count })
		this.setState({ loading: false })
	}

	private handleImageErrored() {}

	private click(product: Product) {
		let url = '#/detail/' + product.kategorie_id + '/' + product.znr
		window.location.href = url
	}

	public componentDidMount() {
		Store.attach(this)
	}

	public render() {
		const { loading } = this.state
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<div className={'' + (loading ? 'unsichtbar' : '')}>
					<div
						className={
							'group-container ' + (loading ? 'unsichtbar' : '')
						}
					>
						<div className="group-container-flex">
							{this.props.group.collection.map(
								(product: Product, i: number) => (
									<div key={i} className="set-card-product">
										<Image
											onLoad={this.handleImageLoaded}
											onError={this.handleImageErrored.bind(
												this
											)}
											alt={product.getBeschrieb(
												this.state.language
											)}
											className="group-picture"
											src={product.picturePaths.medium}
											onClick={() => this.click(product)}
											fluid
										/>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
