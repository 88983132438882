import React from 'react'
import { ProtectedRoute } from '../componentsFunctional/ProtectedRoute'

class Error extends React.Component {
	render() {
		return (
			<ProtectedRoute>
				<div className="text-center" style={{ marginTop: '40px' }}>
					<h1>404</h1>
					<hr />
					<p>Hier wurde leider nichts gefunden.</p>
					<p>
						Zurück zur <a href="#/">Startseite</a> …
					</p>
				</div>
			</ProtectedRoute>
		)
	}
}

export default Error
