import React, { Component } from 'react'
import { ProtectedRoute } from '../componentsFunctional/ProtectedRoute'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Verkauf extends Component {
	render() {
		return (
			<ProtectedRoute>
				<Container>
					<code className="component_info">Verkauf.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>Verkaufen</h1>
								<hr />
								<p>
									Sie wollen Ihre Briefmarkensammlung auflösen
									und verkaufen? Oder Sie haben eine Sammlung
									geerbt und möchten diese nicht weiterführen?
									Dann kontaktieren Sie uns unverbindlich per
									E-Mail oder Telefon. Gerne stehen wir Ihnen
									beratend zur Verfügung und unterbreiten
									Ihnen ein faires Angebot.
									<br />
									<br />
									Telefon: +41 44 777 11 22
									<br />
									E-Mail:{' '}
									<a href="mailto:info@rj-stamp.ch">
										info@rj-stamp.ch
									</a>
								</p>
							</div>
						</Col>
						<Col sm={3}></Col>
					</Row>
				</Container>
			</ProtectedRoute>
		)
	}
}

export default Verkauf // Don’t forget to use export default!
