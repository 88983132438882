import React from 'react'
import scrollToTop from '../functions/scrollToTop'

interface IProps {
	countOfAllListItems: number
	pageIndex: number
	setPageIndex: (input: number) => void
	listItemAmount: number
	maxNumberOfButtons: number
}

export default function Pagination(props: IProps) {
	const currentPage = props.pageIndex + 1

	const clickPrevious = (): void => {
		let previousPage = currentPage - 1
		previousPage = previousPage < 1 ? 1 : previousPage
		changePage(previousPage)
		scrollToTop()
	}

	const clickNext = (): void => {
		const amountOfPages = getAmountOfPages()
		let nextPage = currentPage + 1
		nextPage = nextPage > amountOfPages ? amountOfPages : nextPage
		changePage(nextPage)
		scrollToTop()
	}

	const click = (page: number) => {
		changePage(page)
		scrollToTop()
	}

	const changePage = (page: number) => {
		props.setPageIndex(page - 1)
	}

	const getAmountOfPages = (): number => {
		return Math.ceil(props.countOfAllListItems / props.listItemAmount)
	}

	const renderPointButton = (): JSX.Element => {
		return (
			<button
				disabled={true}
				type="button"
				className="btn btn-outline-primary"
				data-bs-toggle="tooltip"
				data-bs-placement="top"
			>
				…
			</button>
		)
	}

	const renderFirstButtons = (): JSX.Element => {
		const paginationArray: number[] = calculatePaginationArray()
		let renderButton: boolean = false
		let renderPoints: boolean = false

		if (paginationArray[0] !== 1) {
			renderButton = true
		}

		if (paginationArray[0] !== 1 && paginationArray[0] !== 2) {
			renderPoints = true
		}
		return (
			<>
				{renderButton && (
					<button
						type="button"
						onClick={() => click(1)}
						className="btn btn-outline-primary"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title={'1'}
					>
						<span aria-hidden="true">1</span>
					</button>
				)}
				{renderPoints && <>{renderPointButton()}</>}
			</>
		)
	}

	const renderLastButtons = (): JSX.Element => {
		const lastPage: number = getAmountOfPages()
		const paginationArray: number[] = calculatePaginationArray()

		let renderButton: boolean = false
		let renderPoints: boolean = false

		if (paginationArray[paginationArray.length - 1] !== lastPage) {
			renderButton = true
		}

		if (
			paginationArray[paginationArray.length - 1] !== lastPage &&
			paginationArray[paginationArray.length - 1] !== lastPage - 1
		) {
			renderPoints = true
		}

		return (
			<>
				{renderPoints && <>{renderPointButton()}</>}
				{renderButton && (
					<button
						type="button"
						onClick={() => click(lastPage)}
						className="btn btn-outline-primary"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title={String(lastPage)}
					>
						<span aria-hidden="true">{lastPage}</span>
					</button>
				)}
			</>
		)
	}

	const calculatePaginationArray = (): number[] => {
		const amountOfPages = getAmountOfPages()
		const numberOfButtons =
			amountOfPages < props.maxNumberOfButtons
				? amountOfPages
				: props.maxNumberOfButtons
		const halfNumberOfButtons = Math.floor(numberOfButtons / 2)

		let minPageOffset: number = -(currentPage - halfNumberOfButtons) + 1
		minPageOffset = minPageOffset > 0 ? minPageOffset : 0
		const minPage: number =
			currentPage - halfNumberOfButtons < 1
				? 1
				: currentPage - halfNumberOfButtons

		let maxPageOffset: number =
			currentPage + halfNumberOfButtons - amountOfPages
		maxPageOffset = maxPageOffset > 0 ? maxPageOffset : 0
		const maxPage: number =
			currentPage + halfNumberOfButtons > amountOfPages
				? amountOfPages
				: currentPage + halfNumberOfButtons

		let paginationNumbers: number[] = []
		let n = 0
		while (n < amountOfPages) {
			paginationNumbers.push(n + 1)
			n++
		}
		let sliceStart: number = minPage - maxPageOffset
		sliceStart = sliceStart < 1 ? 1 : sliceStart
		const sliceEnd: number = maxPage + minPageOffset

		paginationNumbers = paginationNumbers.slice(sliceStart - 1, sliceEnd)

		return paginationNumbers
	}

	if (props.countOfAllListItems) {
		return (
			<>
				<div className="d-flex justify-content-between">
					<div
						className="btn-group btn-group-sm"
						role="group"
						aria-label="Basic example"
					>
						<button
							disabled={currentPage === 1}
							type="button"
							onClick={clickPrevious}
							className="btn btn-outline-primary"
							id="paginaPrev"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
						>
							<span aria-hidden="true">&nbsp;&lsaquo;&nbsp;</span>
						</button>
						{renderFirstButtons()}
						{calculatePaginationArray().map((p, i) => {
							let className = 'btn btn-outline-primary'
							if (p === currentPage) {
								className = 'btn btn-outline-primary active'
							}
							return (
								<button
									key={i}
									onClick={() => click(p)}
									type="button"
									className={className}
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title={String(p)}
								>
									{p}
								</button>
							)
						})}
						{renderLastButtons()}
						<button
							disabled={currentPage === getAmountOfPages()}
							type="button"
							onClick={clickNext}
							className="btn btn-outline-primary"
							id="paginaNext"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
						>
							<span aria-hidden="true">&nbsp;&rsaquo;&nbsp;</span>
						</button>
					</div>
				</div>
			</>
		)
	}
	return <></>
}
