export default function stylePrice(value: string | null | number): string {
	if (typeof value == 'number') {
		value = String(Math.round(Number(value) * 100) / 100)
	}
	if (value === null || value === 'null') {
		return '– –'
	}
	if (Number(value) === 0) {
		return 'LP'
	}
	let priceArray: string[] = String(value).split('.')
	let temp: RegExpMatchArray | null =
		priceArray[0].match(/\d{1,3}(?=(\d{3})*$)/g)
	let newValue: string = ''
	if (temp !== null) {
		for (let i in temp) {
			newValue = newValue + (i === '0' ? '' : "'") + temp[i]
		}
	}
	if (priceArray[1] === undefined) {
		newValue = newValue + '.00'
	} else {
		const rappen = String(Math.round(Number(value) * 100) / 100).split(
			'.'
		)[1]
		if (rappen !== undefined) {
			if (rappen.length === 1) {
				newValue = newValue + '.' + rappen + 0
			} else {
				newValue = newValue + '.' + rappen
			}
		} else {
			newValue = newValue + '.00'
		}
	}
	return newValue
}
