import Observer from '../observerstore/Observer'
import Product from '../models/Product'
import Picture from '../interfaces/Picture'
import Image from 'react-bootstrap/Image'
import { Language } from '../types/Language'
import GroupCardBottom from './GroupCardBottom'
import Store from '../observerstore/Store'
import Group from '../models/Group'
import React from 'react'
const $ = Store.data

interface Props {
	group: Group
}
interface State {
	loading: boolean
	count: number
	language: Language
}

export default class GroupCard extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			loading: true,
			count: 0,
			language: $.language,
		}
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageErrored = this.handleImageErrored.bind(this)
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	handleImageLoaded() {
		let count = this.state.count + 1
		this.setState({ count: count })
		this.setState({ loading: false })
	}

	handleImageErrored() {
		console.error('Bild konnte nicht geladen werden')
	}

	click(product: Product) {
		let url = '#/detail/' + product.kategorie_id + '/' + product.znr
		window.location.href = url
	}

	getCardPictureClass(): string {
		return (
			'set-card-picture' +
			(this.props.group.getLayoutType() === 6 ? '-great' : '')
		)
	}

	getCardPicturePath(paths: Picture): string {
		return this.props.group.getLayoutType() === 6
			? paths.medium
			: paths.small
	}

	render() {
		const { loading } = this.state
		return (
			<React.Fragment>
				<div className={'' + (loading ? 'unsichtbar' : '')}>
					<code className="component_info">
						{this.constructor.name}
					</code>
					{this.props.group.collection.length !== 0 && (
						<>
							<div className="set-card-title">
								<h1>
									<span className="year">
										{this.props.group.getYear()}
									</span>
									&nbsp;&nbsp;
									{this.props.group.getTitle(
										this.state.language
									)}
								</h1>
								<p>
									{this.props.group.getDescription(
										this.state.language
									)}
								</p>
							</div>
						</>
					)}
					<div
						className={
							'set-card-container ' +
							(loading ? 'unsichtbar' : '')
						}
					>
						<div className="set-card-container-flex">
							{this.props.group.collection.map(
								(product: Product, i: number) => (
									<div key={i} className="set-card-product">
										<Image
											onLoad={this.handleImageLoaded}
											onError={this.handleImageErrored.bind(
												this
											)}
											className={this.getCardPictureClass()}
											src={this.getCardPicturePath(
												product.picturePaths
											)}
											alt={`Briefmarke: ${product.getName(
												this.state.language
											)}`}
											onClick={() => this.click(product)}
											fluid
										/>
										<p>
											<span className="mute">
												CHE{' ' + product.hauser_zusatz}
											</span>{' '}
											{' ' + product.znr}
										</p>
									</div>
								)
							)}
						</div>
						<GroupCardBottom group={this.props.group} />
					</div>
				</div>
			</React.Fragment>
		)
	}
}
