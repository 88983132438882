import { REQUESTURL } from '../constants/urls'
import Group from '../models/Group'
import CategoryCollection from '../models/CategoryCollection'
import { getHeaders } from '../functions/fetchHelpers'

export default class GroupCollection {
	collection: Group[]
	count: number
	localStorageKey: string
	categoryCollection: CategoryCollection

	constructor() {
		this.collection = []
		this.count = 0
		this.localStorageKey = 'token'
		this.categoryCollection = new CategoryCollection()
	}

	async readApi(
		Store: any,
		categories: number[],
		anzahl: number,
		seite: number,
		level: number,
		sortAttribute: string,
		sortOrder: string,
		searchTerm: string,
		searchType: string
	) {
		await this.categoryCollection.readApi()

		let categoryArray: number[] = []
		for (let categorie of categories) {
			categoryArray = categoryArray.concat(
				this.categoryCollection.getCategoryChildNumbers(categorie)
			)
		}
		if (!categoryArray.length) {
			categoryArray = categories
		}

		const url = `${REQUESTURL}group-list/all`
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: getHeaders(),
				body: JSON.stringify({
					searchTerm: searchTerm,
					searchType: searchType,
					categoryIds: categoryArray,
					page: seite,
					pageCount: anzahl,
					sortColumn: sortAttribute,
					sortOrder: sortOrder,
				}),
			})
			const json = await response.json()
			await this.translateApi(json, level, categories)
			Store.notify()
		} catch (err) {
			console.error(err)
		}
	}

	async translateApi(json: any, level: number, categories: number[]) {
		this.collection = []
		for (let i of json.products) {
			let group: Group = new Group()
			await group.translateApi(
				i,
				level,
				categories,
				this.categoryCollection
			)
			this.collection.push(group)
		}
		this.count = Number(json.count)
	}
}
