import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

import Category from '../models/Category'

interface Props {
	activeCategoryId: number
	allCategories: Category
}

const ignoredCategories: number[] = [2, 109, 110]

export default function SidebarComponent(props: Props) {
	return (
		<div className="sidebar-list" style={{ position: 'sticky', top: '10px' }}>
			<ListElement
				category={props.allCategories}
				level={1}
				activeCategoryId={props.activeCategoryId}
			/>
		</div>
	)
}

interface ListElementProps {
	category: Category
	level: number
	activeCategoryId: number
}

function ListElement(props: ListElementProps) {
	const [collapsed, setCollapsed] = useState<boolean>(props.level > 1)

	const getClassName = (): string => {
		if (props.activeCategoryId === props.category.id) {
			return (
				'sidebar-list-item sidebar-list-level-' +
				props.level +
				' sidebar-list-item-active'
			)
		}
		return 'sidebar-list-item sidebar-list-level-' + props.level
	}

	const getLink = (): string => {
		return '#/overview/' + props.category.id + '/15/1/'
	}

	const onClick = (): void => {
		setCollapsed(!collapsed)
	}

	const renderButton = (): JSX.Element => {
		if (props.category.id > 1) {
			return (
				<div className="sidebar-list-item-button" onClick={onClick}>
					{collapsed ? (
						<FontAwesomeIcon icon={faCaretLeft} />
					) : (
						<FontAwesomeIcon icon={faCaretDown} />
					)}
				</div>
			)
		}
		return <></>
	}

	if (!ignoredCategories.includes(props.category.id)) {
		if (props.category.childs.length === 0) {
			return (
				<div className={getClassName()}>
					<a href={getLink()}>
						<div>{props.category.name}</div>
					</a>
				</div>
			)
		} else {
			return (
				<>
					<div className={getClassName()}>
						<a href={getLink()}>
							<div>{props.category.name}</div>
						</a>
						{renderButton()}
					</div>
					<>
						{!collapsed && (
							<>
								{props.category.childs.map(
									(e: Category, i: number) => (
										<ListElement
											category={e}
											key={i}
											level={props.level + 1}
											activeCategoryId={
												props.activeCategoryId
											}
										/>
									)
								)}
							</>
						)}
					</>
				</>
			)
		}
	} else {
		return <></>
	}
}
