import AdditionalInformations from '../components/AdditionalInformations'
import Col from 'react-bootstrap/Col'
import Group from '../models/Group'
import GroupCardBottomDetail from '../components/GroupCardBottomDetail'
import ImageGallery from 'react-image-gallery'
import Observer from '../observerstore/Observer'
import PricesProducts1 from '../layouts/pricesProducts/PricesProducts1'
import PricesProducts10 from '../layouts/pricesProducts/PricesProducts10'
import PricesProducts2 from '../layouts/pricesProducts/PricesProducts2'
import PricesProducts3 from '../layouts/pricesProducts/PricesProducts3'
import PricesProducts4 from '../layouts/pricesProducts/PricesProducts4'
import PricesProducts5 from '../layouts/pricesProducts/PricesProducts5'
import PricesProducts6 from '../layouts/pricesProducts/PricesProducts6'
import PricesProducts7 from '../layouts/pricesProducts/PricesProducts7'
import PricesProducts8 from '../layouts/pricesProducts/PricesProducts8'
import PricesProducts9 from '../layouts/pricesProducts/PricesProducts9'
import Product from '../models/Product'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Store from '../observerstore/Store'
import styleNumber from '../functions/styleNumber'
import { Language } from '../types/Language'

const $ = Store.data

interface ImageGalleryItem {
	original: string
	thumbnail: string
	originalAlt: string
	thumbnailAlt: string
	originalTitle: string
	thumbnailTitle: string
}

interface Props {
	product: Product | undefined
	group: Group
}

interface State {
	bilder: string[]
	language: Language
}

export default class ProductDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			bilder: [],
			language: $.language,
		}

		this.nextProduct = this.nextProduct.bind(this)
		this.previousProduct = this.previousProduct.bind(this)
		this.navigateBack = this.navigateBack.bind(this)
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	imagesVerarbeiten(product: Product): ImageGalleryItem[] {
		const images: ImageGalleryItem[] = []
		images.push({
			original: product.getPicturePaths().large,
			thumbnail: product.getPicturePaths().thumbnail,
			originalAlt: `Briefmarke: ${product.name_de}`,
			thumbnailAlt: `Briefmarke: ${product.name_de}`,
			originalTitle: product.name_de,
			thumbnailTitle: product.name_de,
		})
		return images
	}

	renderSpecialInfos(): React.ReactNode {
		if (this.props.product !== undefined) {
			let ausgabetag: string = this.props.product.ausgabetag
			let druckauflage: string = this.props.product.druckauflage

			if (ausgabetag !== '' && druckauflage !== null) {
				return (
					<div>
						{'Ausgabedatum: ' + ausgabetag}
						<br />
						{'Druckauflage ca. ' + styleNumber(druckauflage)}
						<hr className="zero-line" />
					</div>
				)
			} else if (ausgabetag !== '' && druckauflage === null) {
				return (
					<div>
						{'Ausgabedatum: ' + ausgabetag}
						<hr className="zero-line" />
					</div>
				)
			} else if (ausgabetag === '' && druckauflage !== null) {
				return (
					<div>
						{'Druckauflage ca. ' + styleNumber(druckauflage)}
						<hr className="zero-line" />
					</div>
				)
			}
		} else {
			return ''
		}
	}

	goToFirstProduct() {
		let nextProduct: Product | undefined =
			this.props.group.getFirstProduct()
		if (nextProduct !== undefined) {
			let url =
				'#/detail/' + nextProduct.kategorie_id + '/' + nextProduct.znr
			window.location.href = url
		}
	}

	goToLastProduct() {
		let product: Product | undefined = this.props.group.getLastProduct()
		if (product !== undefined) {
			let url = '#/detail/' + product.kategorie_id + '/' + product.znr
			window.location.href = url
		}
	}

	nextProduct() {
		let nextProduct: Product | undefined = this.props.group.getNextProduct(
			this.props.product
		)
		if (nextProduct) {
			let url =
				'#/detail/' + nextProduct.kategorie_id + '/' + nextProduct.znr
			window.location.href = url
		} else {
			if (this.props.product) {
				switch (this.props.group.getLayoutType()) {
					case 4:
						this.goToFirstProduct()
						return
					case 6:
						this.goToFirstProduct()
						return
					default:
						let product = this.props.product
						let url =
							'#/detail/' +
							product.kategorie_id +
							'/' +
							product.znr +
							'/0'
						window.location.href = url
				}
			}
		}
	}

	previousProduct() {
		let product: Product | undefined = this.props.group.getPreviousProduct(
			this.props.product
		)
		if (product) {
			let url = '#/detail/' + product.kategorie_id + '/' + product.znr
			window.location.href = url
		} else {
			if (this.props.product) {
				switch (this.props.group.getLayoutType()) {
					case 4:
						this.goToLastProduct()
						return
					case 6:
						this.goToLastProduct()
						return
					default:
						let product = this.props.product
						let url =
							'#/detail/' +
							product.kategorie_id +
							'/' +
							product.znr +
							'/0'
						window.location.href = url
				}
			}
		}
	}

	private getCurrentCategoryNumber(input: number[]): number {
		if (input.length > 1) {
			return 1
		} else {
			return input[0]
		}
	}

	navigateBack(): void {
		let url: string = '#/overview/'
		let currentCategory = this.getCurrentCategoryNumber($.url.categories)
		url += currentCategory + '/'
		url += $.url.anzahl + '/'
		url += $.url.seite + '/'
		window.location.href = url
	}

	renderPrices(): React.ReactNode {
		if (this.props.product !== undefined) {
			switch (this.props.group.getLayoutType()) {
				case 2:
					return <PricesProducts2 product={this.props.product} />
				case 3:
					return <PricesProducts3 product={this.props.product} />
				case 4:
					return <PricesProducts4 product={this.props.product} />
				case 5:
					return <PricesProducts5 product={this.props.product} />
				case 6:
					return <PricesProducts6 product={this.props.product} />
				case 7:
					return <PricesProducts7 product={this.props.product} />
				case 8:
					return <PricesProducts8 product={this.props.product} />
				case 9:
					return <PricesProducts9 product={this.props.product} />
				case 10:
					return <PricesProducts10 product={this.props.product} />
				default:
					return <PricesProducts1 product={this.props.product} />
			}
		} else {
			return ''
		}
	}

	render(): React.ReactNode {
		if (this.props.product !== undefined) {
			return (
				<>
					<div id="main">
						<div className="backcross" onClick={this.navigateBack}>
							&#215;
						</div>
						<div id="left" onClick={this.previousProduct}>
							<svg
								fill="currentColor"
								width="20"
								height="20"
								x="0px"
								y="0px"
								viewBox="0 0 13.3 15"
							>
								<path d="M8.9,1.3L7.5,0L0,7.5L7.5,15l1.3-1.3L2.7,7.5L8.9,1.3z" />
							</svg>
						</div>
						<article>
							<code className="component_info">
								{this.constructor.name}
							</code>
							<Row>
								<Col sm={6} className="detail">
									{this.props.product !== undefined && (
										<ImageGallery
											additionalClass="gallery-without-thumbnails"
											items={this.imagesVerarbeiten(
												this.props.product
											)}
											showPlayButton={false}
											showThumbnails={false}
										/>
									)}
								</Col>
								<Col sm={6} className="detail">
									<div className="set-card-title">
										<h1>
											<span className="year">
												{this.props.product.jahr}
											</span>
											&nbsp;&nbsp;
											{this.props.product.getName(
												this.state.language
											)}
										</h1>
										<p>
											{this.props.product.getLangbeschrieb(
												this.state.language
											)}
										</p>
									</div>
									<hr className="zero-line" />
									<p>
										<span className="mute">
											CHE
											{' ' +
												this.props.product
													.hauser_zusatz}
										</span>{' '}
										{' ' + this.props.product.znr}
										<br />
										{this.props.product.getKurzbeschrieb(
											this.state.language
										)}
									</p>
									<hr className="zero-line" />
									{this.renderSpecialInfos()}
									{this.renderPrices()}
									<AdditionalInformations
										product={this.props.product}
									/>
								</Col>
							</Row>
						</article>
						<div id="right" onClick={this.nextProduct}>
							<svg
								fill="currentColor"
								width="20"
								height="20"
								x="0px"
								y="0px"
								viewBox="0 0 13.3 15"
							>
								<path d="M0,1.3L1.3,0l7.5,7.5L1.3,15L0,13.7l6.2-6.2L0,1.3z" />
							</svg>
						</div>
					</div>
					<GroupCardBottomDetail input={this.props.product} />
				</>
			)
		} else {
			return 'Produkt wurde nicht gefunden'
		}
	}
}
