import Observer from '../../observerstore/Observer'
import Table from 'react-bootstrap/Table'
import styleDate from '../../functions/styleDate'
import React from 'react'
import Group from '../../models/Group'
import { Language } from '../../types/Language'
import Store from '../../observerstore/Store'
const $ = Store.data

interface Props {
	group: Group
}

interface State {
	language: Language
}

export default class InfoTable2 extends Observer<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			language: $.language,
		}
	}

	update() {
		this.setStateNStore({
			language: $.language,
		})
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<Table
					responsive
					size="sm"
					borderless={true}
					style={{ marginTop: '22px', lineHeight: '0.9' }}
				>
					{this.props.group.collection.length > 1 && (
						<thead>
							<tr>
								<th className="text-left">
									{'Satzpreis, Nr. ' +
										this.props.group.defineGroupNr() +
										', ' +
										this.props.group.getValueCount()}
								</th>
								<th className="text-right th-price">
									{this.props.group.calculatePrice(
										'postfrisch'
									)}
								</th>
								<th className="text-right th-price">
									{this.props.group.calculatePrice(
										'gestempelt'
									)}
								</th>
								<th className="text-right th-price">
									{this.props.group.calculatePrice('brief')}
								</th>
							</tr>
						</thead>
					)}
					{this.props.group.collection[0].ausgabetag !== '' && (
						<tbody>
							<tr style={{ marginTop: '-20px' }}>
								<td className="text-left">
									{'Ersttag: ' +
										styleDate(
											this.state.language,
											this.props.group.getIssueDate()
										)}
								</td>
								<td className="text-right th-price"></td>
								<td className="text-right th-price">
									{this.props.group.getGroupPrice(
										'vollstempelEt'
									)}
								</td>
								<td className="text-right th-price">
									{this.props.group.getGroupPrice('fdc')}
								</td>
							</tr>
						</tbody>
					)}
				</Table>
			</React.Fragment>
		)
	}
}
