import ParentProduct from '../models/ParentProduct'
import { APIURL } from '../constants/urls'
import Picture from '../interfaces/Picture'

export default class Variety extends ParentProduct {
	abart: string = ''
	abart_beschrieb: string = ''
	abart_pictures: Picture[] = [
		{
			original: `${APIURL}/image/products/default.jpg`,
			thumbnail: `${APIURL}/image/products/default.jpg`,
			small: `${APIURL}/image/products/default.jpg`,
			medium: `${APIURL}/image/products/default.jpg`,
			large: `${APIURL}/image/products/default.jpg`,
		},
	]

	async translateApi(json: any): Promise<void> {
		super.translateApi(json)
		this.abart = json.pro_znr_abart
		this.abart_beschrieb = json.pro_abart_beschrieb_de
		this.abart_pictures = this.getAbartPicturePaths()
	}

	public getAbartPicturePaths(): Picture[] {
		if (this.bild.length) {
			const output: Picture[] = []
			for (const bild of this.bild) {
				const bildpfad = APIURL + bild + '_'
				output.push({
					original: bildpfad + 'original.jpg',
					thumbnail: bildpfad + 'Xx100.jpg',
					small: bildpfad + 'Xx200.jpg',
					medium: bildpfad + 'Xx600.jpg',
					large: bildpfad + 'Xx1600.jpg',
				})
			}
			return output
		} else {
			return [
				{
					original: `${APIURL}/image/products/default.jpg`,
					thumbnail: `${APIURL}/image/products/default.jpg`,
					small: `${APIURL}/image/products/default.jpg`,
					medium: `${APIURL}/image/products/default.jpg`,
					large: `${APIURL}/image/products/default.jpg`,
				},
			]
		}
	}

	getRequestName(): string {
		let znr = this.cleanZnr(this.znr)
		let hauser_zusatz = this.hauser_zusatz
		let abart = this.cleanAbart(this.abart)
		return hauser_zusatz + '_' + znr + '_' + abart
	}

	getDirectoryName(): string {
		let znr = this.cleanZnr(this.znr)
		let hauser_zusatz = this.hauser_zusatz
		let abart = this.cleanAbart(this.abart)
		return 'CHE_' + hauser_zusatz + '_' + znr + '_' + abart
	}

	toPicturePath(input: string): Picture {
		let directory = this.getDirectoryName()
		let file = input
		return super.toPicturePath(directory, file)
	}

	cleanAbart(input: string): string {
		let output: string = input
		output = output.replaceAll('. ', '-')
		return output
	}
}
